.navbar {
  background-color: #f4f4f4;
  box-shadow: -8px 9px 14px -4px rgb(216, 213, 220);
  will-change: background-color;
  animation: navbar 3s ease-in 3s infinite alternate;
}

@keyframes navbar {
  0%,
  10% {
    background-color: #f4f4f4;
  }
  90%,
  100% {
    background-color: #1d2440;
    box-shadow: -8px 9px 14px -4px rgba(216, 213, 220, 0);
  }
}

.links {
  color: rgba(51, 51, 51, 0.75);
  will-change: color;
  animation: links 3s ease-in 3s infinite alternate;
}

@keyframes links {
  0%,
  10% {
    color: rgba(51, 51, 51, 0.75);
  }
  90%,
  100% {
    color: rgba(255, 255, 255, 0.75);
  }
}

.logo_dark {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  will-change: opacity;
  animation: logo_dark 3s ease-in 3s infinite alternate;
}

@keyframes logo_dark {
  0%,
  10% {
    opacity: 1;
  }
  90%,
  100% {
    opacity: 0;
  }
}

.logo_light {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  will-change: opacity;
  animation: logo_light 3s ease-in 3s infinite alternate;
}

@keyframes logo_light {
  0%,
  10% {
    opacity: 0;
  }
  90%,
  100% {
    opacity: 1;
  }
}

.secondary_cta {
  color: #3a3a3a;
  background-color: #e3e3e3;
  will-change: color, background-color;
  animation: secondary_cta 3s ease-in 3s infinite alternate;
}

@keyframes secondary_cta {
  0%,
  10% {
    color: #3a3a3a;
    background-color: #e3e3e3;
  }
  90%,
  100% {
    color: #fff;
    background-color: #071423;
  }
}
